<template>
  <div>
    <slot
      v-if="displayPagination || filterText.length || showSearchTextAlways"
      class="list-filter"
    >
      <div class="d-flex flex-row align-center">
        <v-text-field
          dense
          outlined
          autofocus
          clearable
          hide-details
          :label="computedLabel"
          v-model="filterText"
          :prepend-inner-icon="$icon('i.Search')"
        >
          <template v-slot:append>
            <slot name="append-text" />
          </template>
        </v-text-field>
        <slot name="append-search" />
      </div>
    </slot>
    <v-list :flat="flat">
      <v-list-item-group
        :multiple="multiple"
        v-model="selectedItems"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="itemKey ? item[itemKey] : index"
          :value="item"
          @click="$emit('click',  {$event, item})"
        >
          <slot
            name="list-item"
            :item="{item}"
          >
            <slot
              name="item-selection"
              :item="item"
            />
            <v-list-item-content>
              <slot
                name="item-content"
                :item="item"
              >
                {{item[itemName]}}
              </slot>
            </v-list-item-content>
            <slot
              name="item-action"
              :item="item"
            />
          </slot>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <slot name="pagination">
      <v-pagination
        v-if="displayPagination"
        v-model="currentPage"
        :length="Math.floor((totalItems - 1) / pageSize) + 1"
        :total-visible="pagesVisible"
        :disabled="disablePagination"
      />
    </slot>
  </div>
</template>

<script>
export default {
  data () {
    return {
      toggleValue: false
    }
  },
  computed: {
    computedLabel () {
      return this.label ?? this.$t('t.Filter')
    },
    currentPage: {
      get () {
        return this.selectedPage
      },
      set (page) {
        this.$emit('update:selected-page', Number(page))
      }
    },
    displayPagination () {
      return this.totalItems > this.pageSize
    },
    filterText: {
      get () {
        return this.searchText ?? ''
      },
      set (text) {
        this.$emit('update:search-text', text)
      }
    },
    selectedItems: {
      get () {
        return this.selected
      },
      set (selected) {
        this.$emit('update:selected', selected)
      }
    }
  },
  methods: {
    changeToggleValue () {
      this.toggleValue = !this.toggleValue
      this.$emit('update:toggle-eye', this.toggleValue)
    }
  },
  props: {
    disablePagination: {
      default: false,
      type: Boolean
    },
    flat: Boolean,
    itemKey: String,
    itemName: {
      default: 'name',
      type: String
    },
    items: {
      default: () => [],
      type: Array
    },
    label: {
      type: String
    },
    multiple: Boolean,
    pagesVisible: {
      default: 5,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
    searchText: String,
    selected: {
      type: [Object, Array, Number]
    },
    selectedPage: {
      default: 1,
      type: Number
    },
    totalItems: {
      required: true,
      type: Number
    },
    showSearchTextAlways: Boolean
  },
  watch: {
    items (items) {
      if (items && !items.length && this.currentPage > 1) {
        this.currentPage = 1
      }
      this.$triggerResize()
    }
  }
}
</script>
